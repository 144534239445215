import React, { useState, useEffect, useRef } from 'react';

export default function Header() {
    const [isExpanded, setIsExpanded] = useState(false);
    const headerRef = useRef(null);

    const handleClick = () => {
        setIsExpanded(!isExpanded);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (headerRef.current && !headerRef.current.contains(event.target)) {
                setIsExpanded(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className='header' ref={headerRef}>
            <div className='header_space_1'>
                <img src='../icons/logo_no_fon.png' />
                <div className='header_search_icon'>
                    <svg onClick={handleClick} width="20" height="20" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M13.1996 7.79131C13.1996 9.22569 12.6298 10.6013 11.6156 11.6156C10.6013 12.6298 9.22569 13.1996 7.79131 13.1996C6.35694 13.1996 4.9813 12.6298 3.96705 11.6156C2.95279 10.6013 2.38298 9.22569 2.38298 7.79131C2.38298 6.35694 2.95279 4.9813 3.96705 3.96705C4.9813 2.95279 6.35694 2.38298 7.79131 2.38298C9.22569 2.38298 10.6013 2.95279 11.6156 3.96705C12.6298 4.9813 13.1996 6.35694 13.1996 7.79131ZM12.2426 14.1869C10.6446 15.2991 8.68801 15.7727 6.75821 15.5145C4.8284 15.2563 3.0652 14.2849 1.8158 12.7916C0.566409 11.2983 -0.078643 9.39138 0.00766527 7.44628C0.0939736 5.50118 0.905388 3.65888 2.28213 2.28213C3.65888 0.905388 5.50118 0.0939735 7.44628 0.00766526C9.39138 -0.078643 11.2983 0.566409 12.7916 1.81581C14.2849 3.0652 15.2563 4.8284 15.5145 6.75821C15.7727 8.68801 15.2991 10.6446 14.1869 12.2426L17.9306 15.9854L15.9854 17.9306L12.2426 14.1869Z" fill="#9B9B9B" />
                    </svg>
                </div>
            </div>
            <input
                className="header_search"
                placeholder="Введите название аниме"
                style={{
                    width: isExpanded ? '200px' : '0px',
                    paddingRight: isExpanded ? '20px' : '0px',
                    opacity: isExpanded ? '1' : '0',
                    transition: 'width 0.6s ease, padding-right 0.3s ease, opacity 0.3s ease',
                }}
            />
            <div className='header_space_2'>
                <img src='https://s2.anitype.site/media/user/ilya252609/30' />
            </div>
        </div>
    );
}
