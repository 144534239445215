import React, { Component } from 'react'
import Banner from '../components/banner'
import Popular from '../components/popular'
import Footer from '../components/footer'
import Header from '../components/header'
import Ongoing from '../components/ongoing'
import Anons from '../components/anons'
import Movie from '../components/movie'
import Leader from '../components/leader'

export default class welcome extends Component {
  render() {
    return (
      <>
        <Header />
        <Banner />
        <Popular />
        <Ongoing />
        <Anons />
        <Leader />
        <Movie />
        <Footer />
        <div style={{height: '100px'}}></div>
      </>
    )
  }
}
