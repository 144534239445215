import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import client from './components/apolloClient';
import Welcome from './page/welcome';
import AllOngoing from './page/all=1'
import AllAnons from './page/all=2'
import AllMovie from './page/all=3'
import Anime from './page/anime';

function App() {
  return (
    <ApolloProvider client={client}>
      <div className="m.aniplay.fun">
        <Routes>
          <Route path="/all=1" element={<AllOngoing />}/>
          <Route path="/all=2" element={<AllAnons />}/>
          <Route path="/all=3" element={<AllMovie />}/>
          <Route path="/anime/:id" element={<Anime />}/>
          <Route path="/welcome" element={<Welcome />} />
          <Route path="*" element={<Navigate to="/welcome" replace />} />
        </Routes>
      </div>
    </ApolloProvider>
  );
}

export default App;
