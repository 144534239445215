import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const kindTranslations = {
  tv: 'TV Сериал',
  movie: 'Фильм',
  ova: 'OVA',
  ona: 'ONA',
  special: 'Спецвыпуск',
  tv_special: 'TV Спецвыпуск',
};

const statusTranslations = {
  released: 'Вышел',
  ongoing: 'Онгоинг',
  latest: 'Последний',
  anons: 'Анонсирован',
};

export default function Anime({ anime }) { // Capitalized name
  const displayAnime = anime;
  const opVideo = anime.videos.find((video) => video.kind === 'op');
  const opVideoUrl = opVideo ? opVideo.url : '';
  const opVideoImageUrl = opVideo ? `https:${opVideo.imageUrl}` : '';
  const opVideoName = opVideo ? opVideo.name : 'Название не найдено';

  const navigate = useNavigate();


  const cleanDescription = (description) => {
    return description ? description.replace(/\[.*?\]/g, '') : 'Нет описания';
  };

  const handleBack = () => {
    if (window.history.state && window.history.state.idx > 0) {
      navigate(-1);
    } else {
      navigate('/welcome');
    }
  };

  return (
    <>  
        <div
          className="nav_bar_anime"
        >
            <svg onClick={handleBack} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
                <path d="M4 12H20M4 12L8 8M4 12L8 16" stroke="#ffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
            </svg>
            <span>{anime.russian}</span>
        </div>
        <div className='anime_poster_image_shadow'>
            <img className='anime_posters_img' src={anime.poster.originalUrl} />
        </div>
        <div className='anime_block_transparent'></div>
        <div className='anime_main_block'>
            <div className='anime_main_block_title'>{anime.russian}</div>
            <div className='anime_main_block_title_japanese'>{anime.japanese}</div>
            <div className='anime_main_block_score_and_description'>
                <div className={`anime_main_block_score ${displayAnime.score >= 8 ? 'hard_score' : displayAnime.score <= 7 ? 'low_score' : 'high_score'}`}>{anime.score}</div>
                {anime.name}
            </div>
            <p className='anime_main_block_kind_year_genres'>
                <span>
                  {kindTranslations[anime.kind] || anime.kind}
                </span>
                <span className='anime_main_block_between'>|</span>
                <span>
                  {anime.airedOn ? anime.airedOn.year : 'Не указано'}
                </span>
                <span className='anime_main_block_between'>|</span>
                <span>
                  {anime.genres.filter((genre) => genre.kind === 'genre' || genre.kind === 'demographic')
                      .map((genre) => genre.russian)
                      .join(', ')}
                </span>
            </p>
            <div className='anime_main_butt_watch'>
              <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="-0.5 0 8 8" version="1.1">
                  <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <g id="Dribbble-Light-Preview" transform="translate(-427.000000, -3765.000000)" fill="#000000">
                          <g id="icons" transform="translate(56.000000, 160.000000)">
                              <polygon id="play-[#1001]" points="371 3605 371 3613 378 3609"></polygon>
                          </g>
                      </g>
                  </g>
              </svg>
              Смотреть
            </div>
            <div className='anime_main_dop_butt'>
              <div className='dop_butt'>
                <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M18.543 10.8477H14.3164V12.1471C14.3164 12.6641 14.0938 13.1599 13.6974 13.5254C13.3011 13.891 12.7636 14.0964 12.2031 14.0964C11.6427 14.0964 11.1051 13.891 10.7088 13.5254C10.3125 13.1599 10.0898 12.6641 10.0898 12.1471V10.8477H5.86329C5.67646 10.8477 5.49729 10.9161 5.36518 11.038C5.23308 11.1598 5.15886 11.3251 5.15886 11.4974V18.6445C5.15886 18.8169 5.23308 18.9821 5.36518 19.104C5.49729 19.2258 5.67646 19.2943 5.86329 19.2943H18.543C18.7298 19.2943 18.909 19.2258 19.0411 19.104C19.1732 18.9821 19.2474 18.8169 19.2474 18.6445V11.4974C19.2474 11.3251 19.1732 11.1598 19.0411 11.038C18.909 10.9161 18.7298 10.8477 18.543 10.8477Z" fill="white"></path>
                  <path d="M6.91993 9.54818H17.4863C17.6732 9.54818 17.8523 9.47973 17.9844 9.35788C18.1165 9.23603 18.1908 9.07076 18.1908 8.89844C18.1908 8.72612 18.1165 8.56086 17.9844 8.43901C17.8523 8.31716 17.6732 8.2487 17.4863 8.2487H6.91993C6.7331 8.2487 6.55393 8.31716 6.42182 8.43901C6.28972 8.56086 6.2155 8.72612 6.2155 8.89844C6.2155 9.07076 6.28972 9.23603 6.42182 9.35788C6.55393 9.47973 6.7331 9.54818 6.91993 9.54818Z" fill="white"></path>
                  <path d="M7.97657 6.94923H16.4297C16.6165 6.94923 16.7957 6.88077 16.9278 6.75892C17.0599 6.63707 17.1341 6.47181 17.1341 6.29949C17.1341 6.12717 17.0599 5.9619 16.9278 5.84005C16.7957 5.7182 16.6165 5.64975 16.4297 5.64975H7.97657C7.78974 5.64975 7.61057 5.7182 7.47846 5.84005C7.34636 5.9619 7.27214 6.12717 7.27214 6.29949C7.27214 6.47181 7.34636 6.63707 7.47846 6.75892C7.61057 6.88077 7.78974 6.94923 7.97657 6.94923Z" fill="white"></path>
                </svg>
              </div>
              <div className='dop_butt'>
                <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6.55919 19.0317H15.9143V15.1359C15.9143 14.9293 15.9965 14.7311 16.1427 14.585C16.2889 14.4388 16.4872 14.3568 16.6939 14.3568C16.9007 14.3568 17.099 14.4388 17.2452 14.585C17.3914 14.7311 17.4735 14.9293 17.4735 15.1359V19.8108C17.4735 20.0175 17.3914 20.2157 17.2452 20.3618C17.099 20.5079 16.9007 20.59 16.6939 20.59H5.7796C5.57283 20.59 5.37454 20.5079 5.22834 20.3618C5.08214 20.2157 5 20.0175 5 19.8108V15.1359C5 14.9293 5.08214 14.7311 5.22834 14.585C5.37454 14.4388 5.57283 14.3568 5.7796 14.3568C5.98636 14.3568 6.18465 14.4388 6.33085 14.585C6.47706 14.7311 6.55919 14.9293 6.55919 15.1359V19.0317ZM10.4572 12.7984V15.9151C10.4572 16.1217 10.5393 16.3199 10.6855 16.466C10.8317 16.6121 11.03 16.6942 11.2368 16.6942C11.4435 16.6942 11.6418 16.6121 11.788 16.466C11.9342 16.3199 12.0164 16.1217 12.0164 15.9151V12.7984C12.0176 11.7656 12.4287 10.7754 13.1594 10.0451C13.8902 9.31475 14.8809 8.9039 15.9143 8.90267H18.006L17.0401 9.92648C16.8982 10.0769 16.822 10.2775 16.8282 10.4841C16.8343 10.6907 16.9223 10.8864 17.0728 11.0282C17.2234 11.17 17.4241 11.2462 17.6308 11.24C17.8375 11.2339 18.0333 11.1459 18.1752 10.9955L20.3643 8.67515L20.3799 8.65957C20.4056 8.62511 20.4286 8.58864 20.4485 8.55049C20.4813 8.50928 20.5096 8.4646 20.5327 8.41725C20.6091 8.22995 20.6091 8.02019 20.5327 7.83289C20.5094 7.7856 20.4812 7.74094 20.4485 7.69965C20.4286 7.66151 20.4056 7.62503 20.3799 7.59057L20.3643 7.57499L18.1752 5.25155C18.1053 5.17567 18.0211 5.11441 17.9273 5.07131C17.8336 5.02821 17.7322 5.00414 17.6291 5.00049C17.526 4.99684 17.4232 5.01368 17.3267 5.05003C17.2301 5.08639 17.1417 5.14153 17.0667 5.21228C16.9916 5.28302 16.9313 5.36795 16.8894 5.46215C16.8474 5.55635 16.8245 5.65793 16.8221 5.76102C16.8197 5.86411 16.8378 5.96665 16.8754 6.0627C16.9129 6.15875 16.9692 6.24639 17.0409 6.32055L18.006 7.34436H15.9143C14.4676 7.34621 13.0806 7.92143 12.0576 8.94387C11.0346 9.96631 10.459 11.3525 10.4572 12.7984Z" fill="white"/>
                </svg>
              </div>
              <div className='dop_butt'>
                <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M11.7163 5L8.5983 10.7163V5H6V20.59H8.5983V14.8737L11.7163 20.59H15.354C13.795 18.6845 11.583 16.6492 10.024 14.7437C12.9687 16.476 16.5665 18.3381 19.5113 20.0703V16.9523C17.0862 15.913 12.9619 14.7574 10.5368 13.7181C12.9619 14.0646 17.0862 14.5272 19.5113 14.8737V11.7557L10.5368 12.1796C13.3083 10.9671 16.7398 9.85023 19.5113 8.6377V5.5197C16.5665 7.25193 12.9687 9.42161 10.024 11.1539L15.354 5H11.7163Z" stroke="white" stroke-width="1.04167" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </div>
            </div>
            <div className='line_anime_information_block'></div>
            <p className='anime_main_block_description'>{cleanDescription(displayAnime.description)}</p>
              <div className='anime_main_info_block'>
                <span>
                  Информация
                  <div className='line_anime_info_block'></div>
                </span>
                <div className='element_anime_main_info_block'>
                  <p className='element_anime_main_info_block_title'>Год производства</p>
                  <p>{anime.airedOn ? anime.airedOn.year : 'Не указано'}</p>
                </div>
                <div className='element_anime_main_info_block'>
                  <p className='element_anime_main_info_block_title'>Страна</p>
                  <p>Япония</p>
                </div>
                <div className='element_anime_main_info_block'>
                  <p className='element_anime_main_info_block_title'>Тип</p>
                  <p>{kindTranslations[anime.kind] || anime.kind}</p>
                </div>
                <div className='element_anime_main_info_block'>
                  <p className='element_anime_main_info_block_title'>Студия</p>
                  <p>{anime.studios.length > 0 ? anime.studios[0].name : 'Не указано'}</p>
                </div>
                <div className='element_anime_main_info_block'>
                  <p className='element_anime_main_info_block_title'>Длительность эпизода</p>
                  <p>{anime.duration} мин</p>
                </div>
                <div className='element_anime_main_info_block'>
                  <p className='element_anime_main_info_block_title'>Длительность эпизода</p>
                  <p>{anime.duration} мин</p>
                </div>
                <div className='element_anime_main_info_block'>
                  <p className='element_anime_main_info_block_title'>Статус</p>
                  <p>{statusTranslations[anime.status] || anime.status}</p>
                </div>
                <div className='element_anime_main_info_block'>
                  <p className='element_anime_main_info_block_title'>Жанры</p>
                  <p>{anime.genres
                  .filter((genre) => genre.kind === 'genre' || genre.kind === 'demographic')
                  .map((genre) => genre.russian)
                  .join(', ')}</p>
                </div>
              </div>
              <div className='anime_main_info_block'>
                <span>
                  Опенинг
                  <div className='line_anime_info_block'></div>
                </span>
                <a href={opVideoUrl}>
                  {opVideoUrl && opVideoImageUrl && opVideoName && (
                    <div className='anime_trailer'>
                      <div className='anime_trailer_img'>
                        <div className='anime_trailer_img_play'>
                          <svg width="60px" height="60px" version="1.1" viewBox="0 0 36 36" fill="#ececec">
                            <path d="M 12,26 18.5,22 18.5,14 12,10 z M 18.5,22 25,18 25,18 18.5,14 z"></path>
                          </svg>
                        </div>
                        {opVideoImageUrl && <img src={opVideoImageUrl} />}
                      </div>
                      <div className='anime_trailer_name'>
                        {opVideoName}
                      </div>
                    </div>
                  )}
                </a>
              </div>
              <div className='anime_main_info_block'>
                <span>
                  Кадры
                  <div className='line_anime_info_block'></div>
                </span>
                <div className='list_catalog_content'>
                  <div className='anime_main_info_block_images_url'>
                    {anime.screenshots.map((screenshot) => (
                      <div
                        key={screenshot.id}
                        className='anime_central_photo_'
                        style={{ backgroundImage: `url('${screenshot.originalUrl}')` }}
                      ></div>
                    ))}
                  </div>
                </div>
              </div>
        </div>
    </>
  )
}
