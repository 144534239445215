import React from 'react'; 
import { useQuery, gql } from '@apollo/client';

const GET_ANIME_ANNOUNCEMENTS = gql`
  query GetAnimeAnnouncements {
    animes(kind: "tv,tv_24,tv_13", status: "ongoing,latest", limit: 15 ) {
      id
      name
      russian
      score
      episodes
      episodesAired
      poster {
        originalUrl
      }
    }
  }
`;

export default function Popular() {
    const { loading, error, data } = useQuery(GET_ANIME_ANNOUNCEMENTS);

    if (loading) return(
        <div className='list_catalog'>
            <div className='list_catalog_header'>
                <span>Сейчас популярно</span>
            </div>
            <div className='list_catalog_content'>
            {[
                0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10
            ].map((index) => (
                <div className='anime_block'>
                    <div className='loading_anime_block_poster'>
                        <div className='loading-bar'></div>
                    </div>
                    <p className='loading_anime_block_title'>
                        <div className='loading-bar'></div>
                    </p>
                    <div className='anime_block_info'>
                        <div className='loading_anime_block_info_episode'>
                            <div className='loading-bar'></div>
                        </div>
                        <div>·</div>
                        <div className='loading_anime_block_info_score'>
                            <div className='loading-bar'></div>
                        </div>
                    </div>
                </div>
            ))}
            </div>
        </div>
    );

    if (error) return <></>;

    return (
        <div className='list_catalog'>
            <div className='list_catalog_header'>
                <span>Сейчас популярно</span>
            </div>
            <div className='list_catalog_content'>
                {data.animes.map((anime, index) => {
                    const displayAnime = anime;

                    return (
                        <a href={`/anime/${displayAnime.id}`}>
                            <div className='anime_block' key={index}>
                                <div className='anime_block_poster'>
                                    <img src={displayAnime.poster.originalUrl} alt={displayAnime.name} />
                                </div>
                                <p className='anime_block_title'>{displayAnime.russian}</p>
                                <div className='anime_block_info'>
                                    <div className='anime_block_info_episode'>
                                        {anime.episodesAired} эп
                                    </div>
                                    <div>·</div>
                                    <div
                                        className={`anime_block_info_score ${
                                            displayAnime.score >= 8 ? 'hard_score' : displayAnime.score <= 7 ? 'low_score' : 'high_score'
                                        }`}
                                        >
                                        {(anime.score)}
                                    </div>
                                </div>
                            </div>
                        </a>
                    );
                })}
            </div>
        </div>
    );
}
