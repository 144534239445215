import React, { useState } from 'react'; 
import { useQuery, gql } from '@apollo/client';

const GET_ANIME_ANNOUNCEMENTS = gql`
  query GetAnimeAnnouncements {
    animes(kind: "tv,movie", status: "anons", limit: 100, order: popularity) {
      id
      name
      russian
      score
      episodes
      episodesAired
      poster {
        originalUrl
      }

      description
    }
  }
`;

export default function AllOpenList() {
    const { loading, error, data } = useQuery(GET_ANIME_ANNOUNCEMENTS);

    const cleanDescription = (description) => {
        return description ? description.replace(/\[.*?\]/g, '') : 'Нет описания';
    };

    if (loading) return (
        <>
            <div className='nav_bar_all'>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
                    <path d="M4 12H20M4 12L8 8M4 12L8 16" stroke="#ffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                </svg>
                <span>Анонсы</span>
            </div>
            <div className='list_all'>
            {[
                0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10
            ].map((index) => (
                <div className='elem_list_all'>
                    <div className='elem_list_all_left'>
                        <div className='loading_elem_list_all_left_poster'>
                            <div className='loading-bar'></div>
                        </div>
                    </div>
                    <div className='elem_list_all_right'>
                        <p className='loading_anime_block_title_nav_bar'></p>
                        <div className='anime_block_info'>
                            <div className='loading_anime_block_info_episode'>
                                <div className='loading-bar'></div>
                            </div>
                            <div>·</div>
                            <div className='loading_anime_block_info_score'>
                                <div className='loading-bar'></div>
                            </div>
                        </div>
                        <p className='loading_anime_block_description'>
                            <div className='loading-bar'></div>
                        </p>
                    </div>
                </div>
            ))}
            </div>
        </>
    );

    if (error) return <></>;

    return (
        <>
            <div className='nav_bar_all'>
                <a href='/welcome'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
                        <path d="M4 12H20M4 12L8 8M4 12L8 16" stroke="#ffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                    </svg>
                </a>
                <span>Анонсы</span>
            </div>
            <div className='list_all'>
                {data.animes.map((anime, index) => {
                    const displayAnime = anime;

                    return (
                        <a href={`/anime/${displayAnime.id}`}>
                            <div className='elem_list_all'>
                                <div className='elem_list_all_left'>
                                    <img src={displayAnime.poster.originalUrl}/>
                                </div>
                                <div className='elem_list_all_right'>
                                    <p className='anime_block_title_nav_bar'>{displayAnime.russian}</p>
                                    <p className='anime_block_description'>{cleanDescription(displayAnime.description)}</p>
                                </div>
                            </div>
                        </a>
                    );
                })}
            </div>
        </>
    )
}
