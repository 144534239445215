import React, { useState } from 'react'; 
import { useQuery, gql } from '@apollo/client';

const GET_ANIME_ANNOUNCEMENTS = gql`
  query GetAnimeAnnouncements {
    animes(status: "anons", limit: 15, kind: "tv", order: ranked_random, rating: "pg_13") {
      id
      name
      russian
      score
      episodes
      episodesAired
      poster {
        originalUrl
      }
    }
  }
`;


export default function Anons() {
    const [expandedItem, setExpandedItem] = useState(null);
    const { loading, error, data } = useQuery(GET_ANIME_ANNOUNCEMENTS);

    if (loading) return(
        <div className='list_catalog'>
            <div className='list_catalog_header'>
                <span>Анонсы</span>
                <svg width="8" height="13" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.2648 5.73519C7.68782 6.15821 7.68782 6.84518 7.2648 7.26819L1.85025 12.6827C1.42724 13.1058 0.740271 13.1058 0.317259 12.6827C-0.105753 12.2597 -0.105753 11.5728 0.317259 11.1497L4.967 6.5L0.320643 1.85025C-0.102369 1.42724 -0.102369 0.740271 0.320643 0.317259C0.743655 -0.105753 1.43063 -0.105753 1.85364 0.317259L7.26819 5.73181L7.2648 5.73519Z" fill="#AAAAAA"/>
                </svg>
            </div>
            <div className='list_catalog_content'>
            {[
                0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10
            ].map((index) => (
                <div className='anime_block'>
                    <div className='loading_anime_block_poster'>
                        <div className='loading-bar'></div>
                    </div>
                    <p className='loading_anime_block_title'>
                        <div className='loading-bar'></div>
                    </p>
                    <div className='anime_block_info'>
                        <div className='loading_anime_block_info_episode'>
                            <div className='loading-bar'></div>
                        </div>
                        <div>·</div>
                        <div className='loading_anime_block_info_score'>
                            <div className='loading-bar'></div>
                        </div>
                    </div>
                </div>
            ))}
            </div>
        </div>
    );

    if (error) return <></>;

    return (
        <div className='list_catalog'>
            <div className='list_catalog_header'>
                <a href='/all=2'>
                    <span>Анонсы</span>
                    <svg width="8" height="13" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.2648 5.73519C7.68782 6.15821 7.68782 6.84518 7.2648 7.26819L1.85025 12.6827C1.42724 13.1058 0.740271 13.1058 0.317259 12.6827C-0.105753 12.2597 -0.105753 11.5728 0.317259 11.1497L4.967 6.5L0.320643 1.85025C-0.102369 1.42724 -0.102369 0.740271 0.320643 0.317259C0.743655 -0.105753 1.43063 -0.105753 1.85364 0.317259L7.26819 5.73181L7.2648 5.73519Z" fill="#AAAAAA"/>
                    </svg>
                </a>
            </div>
            <div className='list_catalog_content'>
                {data.animes.map((anime, index) => {
                    const displayAnime = anime;

                    return (
                        <a href={`/anime/${displayAnime.id}`}>
                            <div className='anime_block' key={index}>
                                <div className='anime_block_poster'>
                                    <img src={displayAnime.poster.originalUrl} alt={displayAnime.name} />
                                </div>
                                <p className='anime_block_title'>{displayAnime.russian}</p>
                            </div>
                        </a>
                    );
                })}
            </div>
        </div>
    );
}
