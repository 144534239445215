import React from 'react';
import { useParams } from 'react-router-dom';
import Footer from '../components/footer';
import Anime from '../components/anime';
import { gql, useQuery } from '@apollo/client';

const GET_ANIME_BY_ID = gql`
  query GetAnimeById($id: String!) {
    animes(ids: $id) {
      id
      name
      russian
      japanese
      score
      kind
      status
      episodesAired
      description
      duration
      airedOn {
        year
      }
      genres {
        name
        russian
        kind
      }
      airedOn {
        date
      }
      poster {
        originalUrl
      }
      screenshots {
        originalUrl
      }
      studios {
        name
      }
      nextEpisodeAt
      related {
        anime {
          id
          name
          russian
          score
          poster {
            originalUrl
          }
        }
      }
      videos {
        url
        name
        kind
        imageUrl
      }
      scoresStats {
        score
        count
      }
      externalLinks {
        id
        kind
        url
        createdAt
        updatedAt
      }
      statusesStats { 
        status 
        count 
      }
    }
  }
`;

const AnimePage = () => {
  const { id } = useParams();
  const { loading, error, data } = useQuery(GET_ANIME_BY_ID, {
    variables: { id }
  });

  if (loading) return (
    <div className="kp-loader">
        <div className="kp-loader-circle"></div>
    </div>
  );

  if (error || !data || !data.animes.length) { // Проверка на наличие данных
    return (
      <div className='error_404'>
        <div className='line_top_error_404'>
          <img src='../icons/logo_no_fon.png' alt='Logo'></img>
          <p>Аниме не найдено или вы неправильно указали адрес страницы!</p>
        </div>
        <div className='line_center_error_404'>
          <img src='../icons/eror_image.gif' style={{ width: '200px' }} alt='Error'></img>
          <a href='/welcom'>Главная</a>
        </div>
        <div className='line_bottom_error_404'>
          <a href=''>Не терпи и напиши нам в техническую поддержку</a>
        </div>
      </div>
    );
  }

  const anime = data.animes[0];

  return (
    <div>
      <Anime anime={anime} />
      <div style={{height: '150px'}}></div>
      <Footer />
    </div>
  );
};

export default AnimePage;
