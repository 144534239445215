import React from 'react'

export default function banner() {
    const PosterData = [
        {
            src: 'https://nyaa.shikimori.one/uploads/poster/animes/11061/09a4196c062532ec7a6a0a74ca201fda.jpeg',
            title: 'Охотник х Охотник',
            title_en: 'Hunter x Hunter',
            ids: 11061,
            title_poster: '../media/posters_logo/logo(11061).png',
            width_title_poster: '200px',
            description: 'Охотник — это тот, кто путешествует по миру, выполняя различные опасные миссии: от поимки преступников до поиска сокровищ в неизведанных землях. Главный герой — мальчик по имени Гон . Его отец Джин был охотником, но исчез много лет назад. Гон считает, что если пойдёт по стопам отца и станет охотником, то рано или поздно вновь встретится с ним. Мальчик надеется, что, повстречав отца, наконец сможет задать ему один-единственный вопрос: почему он предпочёл жизнь охотника своему маленькому сынишке. Когда ему исполняется двенадцать, Гон, как в своё время и его отец, покидает родной остров с целью сдать официальный экзамен на охотника. Но экзамен очень сложен, и каждый год множество людей погибают, пытаясь получить это звание. Во время своего путешествия Гон знакомится с Курапикой, Леорио и Киллуа, вместе с которыми ступает на тернистый путь охотника. Ремейк аниме-сериала «Охотник х Охотник» 1999 года.',
        },
    ];

    const poster = PosterData[0];


  return (
    <div className='trailer_posters'>
        <img className='trailer_posters_img' src={poster.src} />
        <div className='shadow_image_trailer_posters'></div>
        <div className='trailers_info'>
            <img
                className="container_title_poster"
                style={{ width: poster.width_title_poster }}
                src={poster.title_poster}
            />
            <div className='container_info_descriptions'>
                {poster.description}
            </div>
            <div className='container_butt_trailer'>
                <div className='container_butt_watch_trailer'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="-0.5 0 8 8" version="1.1">
                        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="Dribbble-Light-Preview" transform="translate(-427.000000, -3765.000000)" fill="#000000">
                                <g id="icons" transform="translate(56.000000, 160.000000)">
                                    <polygon id="play-[#1001]" points="371 3605 371 3613 378 3609"></polygon>
                                </g>
                            </g>
                        </g>
                    </svg>
                    Смотреть
                </div>
                <div className='container_butt_favourites_trailer'>
                    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18.543 10.8477H14.3164V12.1471C14.3164 12.6641 14.0938 13.1599 13.6974 13.5254C13.3011 13.891 12.7636 14.0964 12.2031 14.0964C11.6427 14.0964 11.1051 13.891 10.7088 13.5254C10.3125 13.1599 10.0898 12.6641 10.0898 12.1471V10.8477H5.86329C5.67646 10.8477 5.49729 10.9161 5.36518 11.038C5.23308 11.1598 5.15886 11.3251 5.15886 11.4974V18.6445C5.15886 18.8169 5.23308 18.9821 5.36518 19.104C5.49729 19.2258 5.67646 19.2943 5.86329 19.2943H18.543C18.7298 19.2943 18.909 19.2258 19.0411 19.104C19.1732 18.9821 19.2474 18.8169 19.2474 18.6445V11.4974C19.2474 11.3251 19.1732 11.1598 19.0411 11.038C18.909 10.9161 18.7298 10.8477 18.543 10.8477Z" fill="white"/>
                        <path d="M6.91993 9.54818H17.4863C17.6732 9.54818 17.8523 9.47973 17.9844 9.35788C18.1165 9.23603 18.1908 9.07076 18.1908 8.89844C18.1908 8.72612 18.1165 8.56086 17.9844 8.43901C17.8523 8.31716 17.6732 8.2487 17.4863 8.2487H6.91993C6.7331 8.2487 6.55393 8.31716 6.42182 8.43901C6.28972 8.56086 6.2155 8.72612 6.2155 8.89844C6.2155 9.07076 6.28972 9.23603 6.42182 9.35788C6.55393 9.47973 6.7331 9.54818 6.91993 9.54818Z" fill="white"/>
                        <path d="M7.97657 6.94923H16.4297C16.6165 6.94923 16.7957 6.88077 16.9278 6.75892C17.0599 6.63707 17.1341 6.47181 17.1341 6.29949C17.1341 6.12717 17.0599 5.9619 16.9278 5.84005C16.7957 5.7182 16.6165 5.64975 16.4297 5.64975H7.97657C7.78974 5.64975 7.61057 5.7182 7.47846 5.84005C7.34636 5.9619 7.27214 6.12717 7.27214 6.29949C7.27214 6.47181 7.34636 6.63707 7.47846 6.75892C7.61057 6.88077 7.78974 6.94923 7.97657 6.94923Z" fill="white"/>
                    </svg>
                </div>
            </div>
        </div>
    </div>
  )
}
