import React from 'react';

export default function Leader() {
  return (
    <div className='list_catalog'>
      <div className='list_catalog_header'>
        <span>Топ-5 за месяц</span>
      </div>
      <div className='list_catalog_content'>
        <div className='list_catalog_content_leader'>
            <svg
            width="150"
            viewBox="0 0 214 165"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            >
            <path
                d="M77 32.4018L70 0H164L128.5 135.589H214V165H0L12 135.589H93L122 32.4018H77Z"
                fill="url(#paint0_linear_1_17)"
            ></path>
            <defs>
                <linearGradient
                id="paint0_linear_1_17"
                x1="107"
                y1="0"
                x2="107"
                y2="165"
                gradientUnits="userSpaceOnUse"
                >
                <stop
                    stopColor="#FFFF"
                    data-original-color="#ffffff5e"
                    style={{ transition: 'stop-color 1s' }}
                ></stop>
                <stop
                    offset="0.405"
                    stopColor="#FFFF"
                    data-original-color="#ffffff5e"
                    style={{ transition: 'stop-color 1s' }}
                ></stop>
                <stop
                    offset="0.72"
                    stopColor="#FFFF"
                    data-original-color="#ffffff5e"
                    style={{ transition: 'stop-color 1s' }}
                ></stop>
                <stop
                    offset="1"
                    stopColor="hsla(0, 0%, 100%, 0.15)"
                    data-original-color="#ffffff5e"
                    style={{ transition: 'stop-color 1s' }}
                ></stop>
                </linearGradient>
            </defs>
            </svg>
            <div className='anime_block content_leader'>
                <div className='anime_block_poster'>
                    <img src='https://dere.shikimori.one/uploads/poster/animes/57334/preview_alt-2a346126c6ca6b0b4eb749fefb7ae788.jpeg' />
                </div>
                <p className='anime_block_title'>Дандадан</p>
            </div>
        </div>

        <div className='list_catalog_content_leader'>
            <svg
                width="150"
                viewBox="0 0 214 165"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                d="M6.58907 56.3011H44.6222C44.6222 -5.88024 337.227 33.5172 15.2133 131.299L0 165H214V131.299H114.096C368.668 -4.45625 -0.00136235 -44.3283 6.58907 56.3011Z"
                fill="url(#paint0_linear_1_25)"
                ></path>
                <defs>
                <linearGradient
                    id="paint0_linear_1_25"
                    x1="107"
                    y1="0"
                    x2="107"
                    y2="165"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop
                    stopColor="#FFFF"
                    data-original-color="#ffffff5e"
                    style={{ transition: 'stop-color 1s' }}
                    ></stop>
                    <stop
                    offset="0.405"
                    stopColor="#FFFF"
                    data-original-color="#ffffff5e"
                    style={{ transition: 'stop-color 1s' }}
                    ></stop>
                    <stop
                    offset="0.72"
                    stopColor="#FFFF"
                    data-original-color="#ffffff5e"
                    style={{ transition: 'stop-color 1s' }}
                    ></stop>
                    <stop
                    offset="1"
                    stopColor="hsla(0, 0%, 100%, .15)"
                    data-original-color="#ffffff5e"
                    style={{ transition: 'stop-color 1s' }}
                    ></stop>
                </linearGradient>
                </defs>
            </svg>
            <div className='anime_block content_leader'>
                <div className='anime_block_poster'>
                    <img src='https://nyaa.shikimori.one/uploads/poster/animes/54857/preview_alt-413ef187aa832e2e28758f7eae62745c.jpeg' />
                </div>
                <p className='anime_block_title'>Re:Zero. Жизнь с нуля в альтернативном мире 3</p>
            </div>
        </div>

        <div className='list_catalog_content_leader'>
            <svg
            width="150"
            viewBox="0 0 214 165"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M13.5978 26.4511L21.6558 0H205.478L173.75 41.3008C308.721 116.942 66.4783 249.197 0 90.0265H28.7065C113.819 238.06 311.239 10.6732 71.0109 90.0265L62.4493 64.5035L124.395 26.4511H13.5978Z"
                    fill="url(#paint0_linear_1_31)"
                ></path>
                <defs>
                    <linearGradient
                    id="paint0_linear_1_31"
                    x1="107"
                    y1="0"
                    x2="107"
                    y2="165"
                    gradientUnits="userSpaceOnUse"
                    >
                    <stop
                        stopColor="#FFFF"
                        data-original-color="#ffffff5e"
                        style={{ transition: 'stop-color 1s' }}
                    ></stop>
                    <stop
                        offset="0.405"
                        stopColor="#FFFF"
                        data-original-color="#ffffff5e"
                        style={{ transition: 'stop-color 1s' }}
                    ></stop>
                    <stop
                        offset="0.72"
                        stopColor="#FFFF"
                        data-original-color="#ffffff5e"
                        style={{ transition: 'stop-color 1s' }}
                    ></stop>
                    <stop
                        offset="1"
                        stopColor="hsla(0, 0%, 100%, 0.15)"
                        data-original-color="#ffffff5e"
                        style={{ transition: 'stop-color 1s' }}
                    ></stop>
                    </linearGradient>
                </defs>
            </svg>
            <div className='anime_block content_leader'>
                <div className='anime_block_poster'>
                    <img src='https://nyaa.shikimori.one/uploads/poster/animes/54865/preview_alt-fb9e97ef30267c47ebf49d0d148426d5.jpeg' />
                </div>
                <p className='anime_block_title'>Синяя тюрьма: Блю Лок против юношеской сборной Японии</p>
            </div>
        </div>

        <div className='list_catalog_content_leader'>
            <svg
            width="150"
            viewBox="0 0 214 165"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            >
            <path
                d="M21.5877 0H53.9693L39.8904 73.2154H140.397L155.807 0H188.189L173.82 73.2154H214L208.368 104.518H167.677L155.807 165H121.079L133.809 104.518H0L21.5877 0Z"
                fill="url(#paint0_linear_1_37)"
            ></path>
            <defs>
                <linearGradient
                id="paint0_linear_1_37"
                x1="107"
                y1="0"
                x2="107"
                y2="165"
                gradientUnits="userSpaceOnUse"
                >
                <stop
                    stopColor="#FFFF"
                    data-original-color="#ffffff5e"
                    style={{ transition: 'stop-color 1s' }}
                ></stop>
                <stop
                    offset="0.405"
                    stopColor="#FFFF"
                    data-original-color="#ffffff5e"
                    style={{ transition: 'stop-color 1s' }}
                ></stop>
                <stop
                    offset="0.72"
                    stopColor="#FFFF"
                    data-original-color="#ffffff5e"
                    style={{ transition: 'stop-color 1s' }}
                ></stop>
                <stop
                    offset="1"
                    stopColor="hsla(0, 0%, 100%, 0.15)"
                    data-original-color="#ffffff5e"
                    style={{ transition: 'stop-color 1s' }}
                ></stop>
                </linearGradient>
            </defs>
            </svg>
            <div className='anime_block content_leader'>
                <div className='anime_block_poster'>
                    <img src='https://nyaa.shikimori.one/uploads/poster/animes/56784/preview_alt-5efa37218d52381064bdd851fc1e7ce2.jpeg' />
                </div>
                <p className='anime_block_title'>Блич: Тысячелетняя кровавая война — Конфликт</p>
            </div>
        </div>

        <div className='list_catalog_content_leader'>
            <svg
            width="150"
            viewBox="0 0 214 165"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            >
            <path
                d="M122.077 0H32.1255L16.5982 84.4724C325.002 27.3305 129.135 210.476 36.4089 104.766H0C140.022 282.724 375.742 4.78382 48.9237 54.5356L57.9998 22.0056H115.826L122.077 0Z"
                fill="url(#paint0_linear_1_44)"
            ></path>
            <defs>
                <linearGradient
                id="paint0_linear_1_44"
                x1="107"
                y1="0"
                x2="107"
                y2="165"
                gradientUnits="userSpaceOnUse"
                >
                <stop
                    stopColor="#FFFF"
                    data-original-color="#ffffff5e"
                    style={{ transition: 'stop-color 1s' }}
                ></stop>
                <stop
                    offset="0.405"
                    stopColor="#FFFF"
                    data-original-color="#ffffff5e"
                    style={{ transition: 'stop-color 1s' }}
                ></stop>
                <stop
                    offset="0.72"
                    stopColor="#FFFF"
                    data-original-color="#ffffff5e"
                    style={{ transition: 'stop-color 1s' }}
                ></stop>
                <stop
                    offset="1"
                    stopColor="hsla(0, 0%, 100%, 0.15)"
                    data-original-color="#ffffff5e"
                    style={{ transition: 'stop-color 1s' }}
                ></stop>
                </linearGradient>
            </defs>
            </svg>
            <div className='anime_block content_leader'>
                <div className='anime_block_poster'>
                    <img src='https://nyaa.shikimori.one/uploads/poster/animes/57181/preview_alt-2802c186734a668f13c6d5168f549691.jpeg' />
                </div>
                <p className='anime_block_title'>Голубая шкатулка</p>
            </div>
        </div>
      </div>
    </div>
  );
}
