import React from 'react'

export default function footer() {
  return (
    <div className='footer'>
        <div className='footer_butt active'>
            <svg width="30" height="30" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M28.0611 19.145L16.0306 9.80713L4 19.145V15.3401L16.0306 6L28.0611 15.3379V19.145ZM25.0535 18.806V27.83H19.0382V21.8147H13.0229V27.83H7.00764V18.8071L16.0306 12.0404L25.0535 18.806Z" fill="#FF4A6A"/>
            </svg>
            <p>Главная</p>
        </div>
        <div className='footer_butt'>
            <svg width="30" height="30" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M25.9602 15.1867H20.043V17.006C20.043 17.7297 19.7313 18.4238 19.1764 18.9356C18.6216 19.4474 17.869 19.7349 17.0844 19.7349C16.2997 19.7349 15.5472 19.4474 14.9923 18.9356C14.4375 18.4238 14.1258 17.7297 14.1258 17.006V15.1867H8.20859C7.94704 15.1867 7.6962 15.2825 7.51125 15.4531C7.3263 15.6237 7.2224 15.8551 7.2224 16.0963V26.1023C7.2224 26.3436 7.3263 26.575 7.51125 26.7455C7.6962 26.9161 7.94704 27.012 8.20859 27.012H25.9602C26.2217 27.012 26.4726 26.9161 26.6575 26.7455C26.8425 26.575 26.9464 26.3436 26.9464 26.1023V16.0963C26.9464 15.8551 26.8425 15.6237 26.6575 15.4531C26.4726 15.2825 26.2217 15.1867 25.9602 15.1867Z" fill="#888888"/>
                <path d="M9.68789 13.3675H24.4809C24.7424 13.3675 24.9933 13.2717 25.1782 13.1011C25.3632 12.9305 25.4671 12.6991 25.4671 12.4579C25.4671 12.2166 25.3632 11.9852 25.1782 11.8146C24.9933 11.6441 24.7424 11.5482 24.4809 11.5482H9.68789C9.42633 11.5482 9.17549 11.6441 8.99054 11.8146C8.80559 11.9852 8.70169 12.2166 8.70169 12.4579C8.70169 12.6991 8.80559 12.9305 8.99054 13.1011C9.17549 13.2717 9.42633 13.3675 9.68789 13.3675Z" fill="#888888"/>
                <path d="M11.1672 9.72894H23.0016C23.2631 9.72894 23.514 9.6331 23.6989 9.46251C23.8839 9.29192 23.9878 9.06055 23.9878 8.8193C23.9878 8.57805 23.8839 8.34668 23.6989 8.17609C23.514 8.0055 23.2631 7.90967 23.0016 7.90967H11.1672C10.9056 7.90967 10.6548 8.0055 10.4699 8.17609C10.2849 8.34668 10.181 8.57805 10.181 8.8193C10.181 9.06055 10.2849 9.29192 10.4699 9.46251C10.6548 9.6331 10.9056 9.72894 11.1672 9.72894Z" fill="#888888"/>
            </svg>
            <p>Колекция</p>
        </div>
    </div>
  )
}
